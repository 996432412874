import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/render/project/src/src/components/material-ui-layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2>{`Japanese-American Internment Camps on Masumi Hayashi.com`}</h2>
    <p>{`This website, `}<a parentName="p" {...{
        "href": "http://www.masumihayashi.com"
      }}>{`www.masumihayashi.com`}</a>{`, hosts Professor Hayashi's creative research that deals with the internment of Americans of Japanese ancestry during World War II. This project started with Hayashi's panoramic photo collages which show the remnants of sites of Japanese American Internment camps during World War II and were symbolic of an archeological and historical memory. Professor Hayashi interviewed camp survivors in different areas of the United States and Canada which became part of the artwork installation in galleries and museums. She collected photographs taken by these camp survivors when cameras were contraband. Ms. Hayashi project was a search for a collective memory, having been born in the camps, but having no memories of the camps. It was also a personal pilgrimage. These voices, photo collages, and snapshots have become a project that resonates today as we observe our history today.`}</p>
    <h2>{`American Concentration Camps`}</h2>
    <p>{`"Amercian Concentration Camps" page exhibits artist/Professor Hayashi's panoramic photo collages of the 10 Japanese American relocation camps. These photographs only cover the relocation centers/camps. They do not address the assembly centers, the U.S. Justice Dept. camps or the prison and other WRA facilities.`}</p>
    <h2>{`The Gallery Page`}</h2>
    <p>{`The gallery is the exhibition page of Hayashi's panoramic photo collages of the Japanese American Relocation camps.`}</p>
    <h2>{`The Family Album project`}</h2>
    <p>{`"The Family Album Project" was an exhibition curated by Professor Hayashi for the Cleveland State University Art Gallery and the Ohio Arts Council. Even though cameras were contraband, family album snapshots were taken by internees and visitors to the camps. This part of the camp project defines the use of photography in terms of the Japanese American and Japanese Canadian internment.`}</p>
    <h2>{`The Japanese-Canadian Concentration Camps`}</h2>
    <p>{`The "Canadian Concentration Camps" page exhibits two of the internment camps and the Angler POW camp of the Japanese-Canadian Concentration Camp sites. These few pages do not cover the whole story of the Japanese Canadian internment.`}</p>
    <h2>{`Internee audio interviews`}</h2>
    <p>{`This is a 9 minute edited interview with Japanese American internees recalling evacuation and the heated climate during internment. Memories, survivors of the U.S. Concentration Camps for the Japanese Americans (Nikei) during WW II, from interviews by Masumi Hayashi, in 1992 and 1993. Audio mastered by James Abbott, produced by Masumi Hayashi, 1994, Cleveland, Ohio.`}</p>
    <ul>
      <li parentName="ul">{`Historical documents & Links`}</li>
      <li parentName="ul">{`Executive Order 9066`}</li>
      <li parentName="ul">{`Evacuation - Civilian Exclusion Order no. 5`}</li>
      <li parentName="ul">{`President Clinton apology letter`}</li>
      <li parentName="ul">{`U.S. Map of JA relocation camps`}</li>
      <li parentName="ul">{`Canadian Map of Canadian internment camps`}</li>
    </ul>
    <h2><a parentName="h2" {...{
        "href": "http://www.masumimuseum.com"
      }}>{`www.masumimuseum.com`}</a></h2>
    <h2>{`The Masumi Hayashi Museum`}</h2>
    <p>{`Artist/Professor Masumi Hayashi's other projects and artworks from 1999 to the present are hosted on this website, `}<a parentName="p" {...{
        "href": "http://www.masumimuseum.com."
      }}>{`www.masumimuseum.com.`}</a>{` There are five galleries with over 177 photographs that represent her oeuvre of photo collages, including the prison series, the post-industrial series, the cityworks series, the EPA Superfund series, and the Asian temples and ancestral worship series.`}</p>
    <h4>{`PERMISSION: Internment camp statistical information is presented with the permission of the Japanese American National Museum and Brian Niiya, 1997.`}</h4>
    <h4>{`FUNDING SOURCES: Includes an Ohio Arts Council Project Grant, and Artist Fellowship Grant, Cleveland State University faculty research grants, and a U.S. Civil Liberties Educational Fund Research grant.`}</h4>
    <h4>{`COPYRIGHT: No reuse of any content from this web site is permitted without written permission of the author/artist, Professor Masumi Hayashi, and the acknowledgement and notation of the web site as source material.`}</h4>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      